.sectionblock {
  padding: 45px 0;
  @include sp {
    padding: 0;
  }
  &--noBottom {
    padding-bottom: 0;
  }
  &--gray {
    background: #f7f6f4;
  }
  &--bgC {
    background: url(dist/img/bg.png) no-repeat;
    background-position: 115% 34px;
    background-size: 37%;
  }
}
// 見出し
.sectionTitle {
  text-align: center;
  font-size: 4rem;
  padding: 20px 0;
  @include sp {
    font-size: 2.9rem;
    padding-top: 40px;
    padding-bottom: 0;
  }
  &:before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    background: url(dist/img/headline_icon.png) no-repeat;
    background-size: contain;
    vertical-align: top;
    margin-right: 10px;
  }
}

// サービス一覧
.serviceContList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: $main_width;
  margin: 0 auto;
  &__item {
    width: 49%;
    padding: 30px;
    margin: 20px 0;
    box-shadow: 1px 3px 5px 2px #d1d1d1;
    @include sp {
      width: 100%;
    }
    &::before {
      background-color: rgba(0, 0, 0, 0.4);
      /* 自由に位置指定 */
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: " ";
      transition: all 0.5s;
    }
    // &:hover:before {
    //   opacity: 0.5;
    // }
    &--01 {
      background: url(dist/img/planning.png);
      background-size: cover;
      position: relative;
    }
    &--02 {
      background: url(dist/img/creative.png);
      background-size: cover;
      position: relative;
    }
    &--03 {
      background: url(dist/img/marketing.png);
      background-size: cover;
      position: relative;
    }
    &--04 {
      background: url(dist/img/analysis.png);
      background-size: cover;
      position: relative;
    }
  }
  &__link {
    text-decoration: none;
    color: #fff;
    pointer-events: none;
    position: relative;
    width: 100%;
  }
}
.serviceContDt {
  font-size: 3rem;
  font-weight: bold;
}
.serviceContDd {
  padding-top: 25px;
}

// about
.aboutCont {
  width: $main_width;
  margin: 0 auto;
}
.aboutContList {
  width: 60%;
  margin: 0 auto;
  @include tab {
    width: 100%;
  }
  &__item {
    margin: 40px 0;
  }
}
.aboutContListDL {
  display: flex;
  flex-wrap: wrap;
}
.aboutContListDt {
  width: 100px;
  // @include sp {
  //   margin-bottom: 20px;
  //   background: #18abc1;
  //   color: #fff;
  //   width: 100%;
  //   font-weight: bold;
  //   padding: 10px;
  // }
  @include tab {
    margin-bottom: 20px;
    background: #18abc1;
    color: #fff;
    width: 100%;
    font-weight: bold;
    padding: 10px;
  }
  &__text {
    text-align: center;
    color: #3fa9f5;
    width: 100%;
    border: solid 1px #3fa9f5;
    display: inline-block;
    border-radius: 12px;
    @include tab {
      text-align: left;
      color: #fff;
    }
  }
}
.aboutContListDd {
  width: 80%;
  margin-left: 20px;
  @include tab {
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }
}
.businessList {
  display: flex;
  flex-wrap: wrap;
  @include sp {
    display: block;
  }
  &__item:not(:last-child) {
    &:after {
      content: "　|　";
      @include sp {
        content: none;
      }
    }
  }
}
