.contactCont {
  width: 40%;
  margin: 0 auto;
  @include sp {
    width: $main_width;
  }
}
.contactLabel {
  margin: 30px 0;
  display: block;
}
.inputBox {
  background: #fff;
  display: block;
  border: solid 1px #cccccc;
  width: 100%;
  margin-top: 15px;
  padding: 5px;
}
.inputText {
  background: #fff;
  display: block;
  border: solid 1px #cccccc;
  width: 100%;
  margin-top: 15px;
  padding: 5px;
}
.noRequire {
  color: #fff;
  background: #cccccc;
  padding: 4px 13px;
  margin-right: 1rem;
  font-size: 1.3rem;
}
.require {
  color: #fff;
  background: #ce0f7f;
  padding: 4px 13px;
  margin-right: 1rem;
  font-size: 1.3rem;
}
.submitBtn {
  display: block;
  text-align: center;
  width: 50%;
  margin: auto;
  font-weight: bold;
  color: #fff;
  border-radius: 50px;
  padding: 18px 0;
  box-shadow: 1px 3px 5px 2px #d1d1d1;
  background: #1babc5; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #1babc5 1%,
    #3da9f2 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #1babc5 1%,
    #3da9f2 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #1babc5 1%,
    #3da9f2 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1babc5', endColorstr='#3da9f2',GradientType=1 ); /* IE6-9 */
  transition: all 0.5s;
  @include sp {
    width: 100%;
  }
  &:hover {
    opacity: 0.5;
  }
}
