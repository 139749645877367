@mixin flex {
  display: flex;
  flex-wrap: wrap;
}

@mixin center-x {
  margin: 0 auto;
}

@mixin sp {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin sp_320 {
  @media screen and (max-width: 320px) {
    @content;
  }
}
@mixin tab {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin align {
  text-indent: -1rem;
  margin-left: 1rem;
}

@mixin font-awesome {
  font-family: "Font Awesome 5 Free";
}

// アンダーラインアニメーション
@mixin hover-underline(
  $type: slide,
  $dir: left-right,
  $weight: 2px,
  $color: $base_c
) {
  @if $dir == null {
    @if $type == fade {
      $dir: "top";
    } @else if $type == slide {
      $dir: "center";
    }
  }

  position: relative;
  display: inline-block;
  text-decoration: none;
  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: $weight;
    background: $color;
    @if $type == fade {
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;
      @if $dir == bottom {
        bottom: $weight * -4;
      } @else {
        bottom: $weight;
      }
    } @else if $type == slide {
      bottom: $weight * -1;
      transform: scale(0, 1);
      transition: transform 0.3s;
      @if $dir == left-right or $dir == right-in-left {
        transform-origin: left top;
      } @else if $dir == right-left or $dir == left-in-right {
        transform-origin: right top;
      } @else {
        transform-origin: center top;
      }
    }
  }
  &:hover::after {
    @if $type == fade {
      bottom: $weight * -2;
      opacity: 1;
      visibility: visible;
    } @else if $type == slide {
      @if $dir == left-in-right {
        transform-origin: left top;
      } @else if $dir == right-in-left {
        transform-origin: right top;
      }
      transform: scale(1, 1);
    }
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin grad-middleheading {
  background: rgb(69, 142, 9);
  background: -moz-linear-gradient(
    90deg,
    rgba(69, 142, 9, 1) 0%,
    rgba(69, 142, 9, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(69, 142, 9, 1) 0%,
    rgba(69, 142, 9, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(69, 142, 9, 1) 0%,
    rgba(69, 142, 9, 1) 25%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#458e09",endColorstr="#ffffff",GradientType=1);
}
