.globalHeader {
  line-height: 0;
  &__top {
    width: $main_width;
    margin: 0 auto;
    padding: 10px;
  }
}

.logo {
  width: 15%;
  @include sp {
    width: 54%;
  }
}
