/****************
基本設定
*****************/
// 色の指定
$base_c: #ffffff;
$key_c: #18abc1;
$accent_c: #ce0f7f;

// フォントスタイル
$font--serif: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN",
  "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$font--sans: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro",
  "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

// メインコンテンツの幅
$main_width: 95%;

// コンテンツ感の幅
$content--space: 20px;

// コンテンツ内部のpadding
$wrap--padding: 20px;

// 横並び
@mixin flex {
  display: flex;
}

html {
  font-size: 62.5%;
}
body {
  font-family: $font--sans;
  font-size: 1.6rem;
}
img {
  width: 100%;
  height: auto;
}
ul,
ol {
  list-style: none;
}
